import { LocationState, HotelLocationState } from "stores/wizard/state/global/location";
import { TravelersState } from "stores/wizard/state/global/travelers";
import { DateState } from "stores/wizard/state/global/date";
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { Travelers, TravelersMetadata } from "src/components/shared/TravelersField/typings";
import { ValidationState } from "./validations/typings";

// TODO: don't store loc tokens in state, only strings?
// TODO: do we still need config after we have constructed state? can we discard after constructor has run?
export enum PackagesSubLobs {
  FLIGHT_HOTEL = "fh",
  FLIGHT_HOTEL_CAR = "fhc",
  FLIGHT_CAR = "fc",
  HOTEL_CAR = "hc",
  HOTELS = "h",
  FLIGHTS = "f",
  CARS = "c",
}

export enum CarSubLOBs {
  RENTAL = "carRental",
  GROUND_TRANSPORTATION = "groundTransport",
}

export enum LocationType {
  AIRPORT = "AIRPORT",
  HOTEL = "HOTEL",
}

export enum FlightType {
  ONE_WAY = "oneway",
  ROUND_TRIP = "roundtrip",
  MULTI_CITY = "multicity",
  DEFAULT = "default",
}

export enum HotelRatesType {
  STANDALONE = "standalone",
  PACKAGE_RATES = "packageRates",
  NEW_PROPERTY_SEARCH = "newPropertySearch",
}

export abstract class LOBState {
  public validations?: ValidationState;

  public config: any;

  public location: LocationState;

  public hotelLocation?: HotelLocationState;

  public date: DateState;

  public travelers?: TravelersState;

  public hotelTravelersMetadata?: TravelersMetadata;

  public hotelPackageTravelersMetadata?: TravelersMetadata;

  public nonHotelTravelersMetadata?: TravelersMetadata;

  public cruiseTravelersMetadata?: TravelersMetadata;

  public subLOBState: string;

  public travelersValueChanged?: boolean;

  public originInvalidKey?: string | "";

  public destinationInvalidKey?: string | "";

  public dateStartInvalidKey: string;

  public dateEndInvalidKey?: string;

  public hotelTravelersInvalidKey?: string;

  public nonHotelTravelersInvalidKey?: string;

  public tabSelected?: boolean;

  public moduleName?: string;

  public trackingEnabled?: boolean;

  public isDesktop?: boolean;

  public abstract overrideConfig: (callback: () => void) => void;

  public abstract updateSubLOBState: (subLOBState: string) => void;

  public abstract updateDateSelection: (start: Date, end: Date) => void;

  public abstract updateOriginSelection?: (selection: TypeaheadSelection, index?: number) => void;

  public abstract updateDestinationSelection: (selection: TypeaheadSelection, index?: number) => void;

  public abstract updateHotelDestinationSelection?: (selection: TypeaheadSelection, index?: number) => void;

  public abstract swapLocations?: (index?: number) => void;

  public abstract updateHotelTravelersSelection?: (travelers: Travelers) => void;

  public abstract updateNonHotelTravelersSelection?: (travelers: Travelers) => void;

  public abstract validateEndDateField?: () => boolean;

  public abstract validateMaxDateRange?: () => boolean;

  public abstract validateLessThanNTravelers?: () => boolean;

  public abstract validateUnattendedInfantInLap?: () => boolean;

  public abstract validateInfantsPerTraveler?: () => boolean;

  public abstract validateTravelersField?: () => boolean;

  public abstract validateForm?: () => boolean;

  public abstract resetValidations: () => void;

  public abstract setTravelersValue?: () => void;

  public abstract submit: (event: React.FormEvent) => void;

  public abstract updateDateFromConfig: () => void;
}
