import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { action, computed, observable, makeObservable } from "mobx";
import { HotelConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { Room, Travelers } from "src/components/shared/TravelersField/typings";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import {
  TypeaheadDataProps,
  TypeaheadSearchDetail,
  TypeaheadSelectionProps,
} from "src/components/flexComponents/WizardHotelPWA/typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Logger } from "bernie-logger";
import { travelersStateOnConfig } from "../global/travelers/travelersState";
import { TravelersState } from "stores/wizard/state/global";
import { HotelRatesType } from "stores/wizard/state/typings";
import { TravelAgentSearchWizardConfigQueryQuery } from "__generated__/typedefs";
import { getCurrentLocationSelection } from "components/utility/GeolocationUtil";
import { ValidationData, ValidationState } from "../validations/typings";
import { WizardValidationState } from "../validations/WizardValidationState";

export interface ToggleCheckbox {
  stateToForce?: boolean;
}

export class HotelWizardState implements LOBState {
  logger: Logger;

  private globalState: GlobalWizardState;

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public get location() {
    return this.globalState.location;
  }

  public config: HotelConfig;
  public subLOBState: HotelRatesType = HotelRatesType.STANDALONE;
  public validations: ValidationState;
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public numberOfErrors = 0;
  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;
  public isGeolocationAllowed = true;
  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  //Since this LOB doesn't have a subLOB, setting this as empty so the queryParam is removed when navigating to this LOB
  public updateSubLOBState(subLOBState: HotelRatesType) {
    this.subLOBState = subLOBState;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public get date() {
    return this.globalState.date;
  }

  public get hotelTravelersMetadata() {
    return travelersMetadata(this.travelers.hotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  public hotelTravelers: TravelersState;

  public get travelers() {
    return this.hotelTravelers;
  }

  public hotelTabSelected = true;

  public toggleTabSelected = () => {
    this.hotelTabSelected = !this.hotelTabSelected;
  };

  public isMultiplePlacesChecked = false;

  public toggleMultiplePlacesCheckbox = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isMultiplePlacesChecked = stateToForce ?? !this.isMultiplePlacesChecked;
  };

  public isAddAFlightChecked = false;

  public toggleAddAFlightCheckbox = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isAddAFlightChecked = stateToForce ?? !this.isAddAFlightChecked;
  };

  public isAddACarChecked = false;

  public toggleAddACarCheckbox = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isAddACarChecked = stateToForce ?? !this.isAddACarChecked;
  };

  public isPayWithPointsChecked = false;

  public togglePayWithPointsChecked = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isPayWithPointsChecked = stateToForce ?? !this.isPayWithPointsChecked;
  };

  public isPackageRatesIncluded = false;

  public togglePackageRatesIncluded = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isPackageRatesIncluded = stateToForce ?? !this.isPackageRatesIncluded;
  };

  /**
   * we need to keep track of whether or not this toggle has been rendered since we need
   * to send some query param only when it's rendered and unchecked
   */
  public isPackageRatesToggleVisible = false;

  public setPackageRatesToggleVisible = () => {
    this.isPackageRatesToggleVisible = true;
  };

  public hotelTypeSubTab: HotelRatesType = HotelRatesType.STANDALONE;

  public updateHotelTypeSubTab(hotelTypeSubTab: HotelRatesType) {
    this.cleanErrorState();
    this.hotelTypeSubTab = hotelTypeSubTab;
    this.subLOBState = hotelTypeSubTab;
  }

  public isPackageRatesTermsChecked = false;

  public togglePackageRatesTermsCheckbox = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isPackageRatesTermsChecked = stateToForce ?? !this.isPackageRatesTermsChecked;
  };

  public isNewExperienceTermsChecked = false;

  public toggleNewExperienceTermsCheckbox = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isNewExperienceTermsChecked = stateToForce ?? !this.isNewExperienceTermsChecked;
  };

  public get hotelPackageType() {
    if (this.isAddAFlightChecked && this.isAddACarChecked) {
      return "fhc";
    }
    if (this.isAddAFlightChecked) {
      return "fh";
    }
    if (this.isAddACarChecked) {
      return "hc";
    }

    return "";
  }

  public validateHotelPackage = () => {
    const isPackageValid = this.globalState.validateLessThanNTravelers(
      this.hotelTravelersMetadata,
      this.config.travelersPackage
    );
    const isAddOptionValid = (!this.isAddAFlightChecked && !this.isAddACarChecked) || isPackageValid;
    return isAddOptionValid;
  };

  public regionId: string;

  public setRegionId(regionId: string) {
    this.regionId = regionId;
  }

  public get hotelRegionId() {
    return this.regionId;
  }

  public htgStartDate = "";

  public get startDate() {
    return this.htgStartDate;
  }

  public setHtgStartDate(htgStartDate: string) {
    this.htgStartDate = htgStartDate;
  }

  public htgEndDate = "";

  public get endDate() {
    return this.htgEndDate;
  }

  public setHtgEndDate(htgEndDate: string) {
    this.htgEndDate = htgEndDate;
  }

  public get formAction() {
    if (this.hotelTypeSubTab === HotelRatesType.NEW_PROPERTY_SEARCH) {
      return this.config.form.actionPropertySearch;
    }

    if (this.hotelTypeSubTab !== HotelRatesType.PACKAGE_RATES && (this.isAddAFlightChecked || this.isAddACarChecked)) {
      return this.config.form.actionPackage;
    }

    return this.config.form.action;
  }

  // validations
  public destinationInvalidKey: string;
  public originInvalidKey: string;
  public hotelTravelersInvalidKey = "";
  public travelAgentPackageRatesDetail: NonNullable<
    TravelAgentSearchWizardConfigQueryQuery["travelAgentSearchWizardConfig"]
  >["packageRatesDetail"];

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public validateLessThanNTravelers = () => {
    const isValid = this.globalState.validateLessThanNTravelers(this.hotelTravelersMetadata, this.config.travelers);
    if (!isValid || !this.validateHotelPackage()) {
      this.hotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() && this.validateChildrenFields() && this.validateInfantFields();

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public advancedOptionsFilterVisible = true;
  public checkboxFilterChecks: any = {
    businessFriendly: false,
    familyFriendly: false,
    freeCancellation: false,
    breakfastIncluded: false,
  };

  public resetValidations = () => {
    this.destinationInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.hotelTravelersInvalidKey = "";
  };

  public overrideConfig(callback: () => void) {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public sourceIdSelected = "";

  public formatTravelersFromHistoryDetail = (detail: TypeaheadSearchDetail): Room[] => {
    const adults = detail.adultTravelerCounts || [];
    const childrenAges = detail.childrenAges || [];
    const rooms = adults.map((adultsInRoom) => {
      const childrenInRoom = childrenAges.flatMap((children) => children.map((child) => ({ age: child })));
      return { adults: adultsInRoom, children: childrenInRoom, infants: [] };
    });
    return rooms;
  };

  public updateDestinationSelection = (selection: TypeaheadSelection) => {
    const { historyDetail } = this.config.location.destination.essAdapterConfig;
    const { data } = (selection as TypeaheadSelectionProps) || {};
    const { selected } = data || "";
    this.sourceIdSelected = selected;
    this.globalState.updateDestinationSelection(selection);
    this.destinationInvalidKey = "";

    if (historyDetail) {
      this.prefillFormWithHistoryDetail(data);
    }
  };

  public getDateTimezoneOffsetFromDatestamp = (date: number): Date => {
    const dateFormatted = new Date(date);
    const dateOffset = dateFormatted.getTimezoneOffset() * 60000;
    return new Date(dateFormatted.getTime() + dateOffset);
  };

  public prefillFormWithHistoryDetail = (data: TypeaheadDataProps) => {
    if (data.searchDetail) {
      const { searchDetail } = data;
      if (searchDetail.endDate && searchDetail.startDate) {
        const { startDate, endDate } = searchDetail;
        this.updateDateSelection(
          this.getDateTimezoneOffsetFromDatestamp(startDate),
          this.getDateTimezoneOffsetFromDatestamp(endDate)
        );
      }

      if (
        (searchDetail.adultTravelerCounts || searchDetail.childrenAges) &&
        searchDetail.roomCount === 1 &&
        searchDetail.lob === "Lodging"
      ) {
        this.updateHotelTravelersSelection({
          rooms: this.formatTravelersFromHistoryDetail(searchDetail),
          infantSeating: this.travelers.hotel.infantSeating,
        });
      }
    }
  };

  public updateOriginSelection = (selection: TypeaheadSelection) => {
    this.globalState.updateOriginSelection(selection);
    this.originInvalidKey = "";
  };

  public changeFormFilterProperties = (event: React.ChangeEvent<HTMLFormElement>) => {
    const { key: filterKey, checked: isChecked, name: filterName, value: filterValue } = event.currentTarget;
    this.checkboxFilterChecks[filterKey] = isChecked;

    const trackingInfo: FlexTrackingInfo = {
      moduleName: "HOT.SF",
      rfrr: `${filterName}.${filterValue}.${isChecked}`,
      action: Action.CLICK,
      linkName: `${filterName}.${filterValue}`,
    };

    sendDelayedTrackEvent(trackingInfo, this.config.analytics);
  };

  public updateHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateHotelTravelersSelection(travelers, this.travelers);
    this.hotelTravelersInvalidKey = "";
  };

  public updateDestinationFromContext(context: ExtendedContextStore) {
    const location = context?.searchContext?.location || context?.searchContext?.destination;

    if (location?.extendedName) {
      this.globalState.updateDestinationFromContext(
        location.extendedName,
        location.id || "",
        "",
        location.geoCoordinate ? `${location.geoCoordinate.latitude},${location.geoCoordinate.longitude}` : "",
        location.code || "",
        "",
        ""
      );
      this.destinationInvalidKey = "";
    }
  }

  public updateStateValidations = (validationsData: any) => {
    this.updateLocErrorKeys(validationsData.invalidKeys);
    this.errorInputRef = validationsData.inputReference;
    this.numberOfErrors = validationsData.numberOfErrors;
  };

  public updateLocErrorKeys = (invalidKeys: any) => {
    this.dateEndInvalidKey = invalidKeys.dateEnd;
    this.destinationInvalidKey = invalidKeys.destination;
    this.hotelTravelersInvalidKey = invalidKeys.travelers;
    this.originInvalidKey = invalidKeys.origin;
  };

  private submitWithUserLocation = (event: React.FormEvent) => {
    event.preventDefault();

    getCurrentLocationSelection()
      .then((selection) => {
        this.updateDestinationSelection(selection);
        (document.getElementById(this.config.elementId!) as HTMLFormElement)?.submit();
      })
      .catch(() => {
        this.isGeolocationAllowed = false;
        this.postSubmit();
      });
  };

  private postSubmit = (event?: React.FormEvent) => {
    const { origin, destination } = this.location;
    const { start, end } = this.date;

    const dataToValidate: ValidationData = {
      location: {
        origin: this.isAddAFlightChecked ? origin.value : null,
        destination: destination.value,
      },
      dates: {
        start,
        end,
      },
      references: this.wizardInputsArray,
    };

    const { isValid, data } = this.validations.validateForm(dataToValidate, this.config);

    if (!isValid) {
      this.updateStateValidations(data);
      event?.preventDefault();
      this.validations.focusErrorSummary();
    }
  };

  public submit = (event: React.FormEvent) => {
    if (this.config.location.destination.defaultToUserLocation && !this.location.destination.value) {
      return this.submitWithUserLocation(event);
    }

    this.postSubmit(event);
  };

  /**
   * Cleans error state in all forms
   */

  public cleanErrorState = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  public updateFromTravelAgentConfig(
    travelAgentConfig: TravelAgentSearchWizardConfigQueryQuery["travelAgentSearchWizardConfig"]
  ) {
    if (travelAgentConfig) {
      this.config.enablePackageRates = travelAgentConfig.packageRatesEnabled;
      if (travelAgentConfig.enabledLobs && travelAgentConfig.enabledLobs.length === 1) {
        this.config.heading.hideLobTitle = false;
      }
      if (travelAgentConfig.packageRatesDetail) {
        this.travelAgentPackageRatesDetail = travelAgentConfig.packageRatesDetail;
      }
    }
  }

  constructor(config: HotelConfig, globalState: GlobalWizardState, logger: Logger) {
    makeObservable(this, {
      config: observable,
      subLOBState: observable,
      errorInputRef: observable,
      numberOfErrors: observable,
      errorSummaryRef: observable,
      isGeolocationAllowed: observable,
      hotelTravelers: observable,
      hotelTabSelected: observable,
      isMultiplePlacesChecked: observable,
      isAddAFlightChecked: observable,
      isAddACarChecked: observable,
      isPayWithPointsChecked: observable,
      isPackageRatesIncluded: observable,
      isPackageRatesToggleVisible: observable,
      hotelTypeSubTab: observable,
      isPackageRatesTermsChecked: observable,
      isNewExperienceTermsChecked: observable,
      regionId: observable,
      htgStartDate: observable,
      htgEndDate: observable,
      destinationInvalidKey: observable,
      originInvalidKey: observable,
      hotelTravelersInvalidKey: observable,
      travelAgentPackageRatesDetail: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      advancedOptionsFilterVisible: observable,
      checkboxFilterChecks: observable,
      sourceIdSelected: observable,
      location: computed,
      travelersValueChanged: computed,
      date: computed,
      hotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      travelers: computed,
      hotelPackageType: computed,
      hotelRegionId: computed,
      startDate: computed,
      endDate: computed,
      formAction: computed,
      updateSubLOBState: action,
      setTravelersValue: action,
      toggleTabSelected: action,
      toggleMultiplePlacesCheckbox: action,
      toggleAddAFlightCheckbox: action,
      toggleAddACarCheckbox: action,
      togglePayWithPointsChecked: action,
      togglePackageRatesIncluded: action,
      setPackageRatesToggleVisible: action,
      updateHotelTypeSubTab: action,
      togglePackageRatesTermsCheckbox: action,
      toggleNewExperienceTermsCheckbox: action,
      setRegionId: action,
      setHtgStartDate: action,
      setHtgEndDate: action,
      moreThanOneError: action,
      validateLessThanNTravelers: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      resetValidations: action,
      overrideConfig: action,
      updateDateSelection: action,
      formatTravelersFromHistoryDetail: action,
      updateDestinationSelection: action,
      getDateTimezoneOffsetFromDatestamp: action,
      prefillFormWithHistoryDetail: action,
      updateOriginSelection: action,
      changeFormFilterProperties: action,
      updateHotelTravelersSelection: action,
      cleanErrorState: action,
      updateFromTravelAgentConfig: action,
    });
    this.config = config;
    this.globalState = globalState;
    this.logger = logger;
    this.hotelTravelers = travelersStateOnConfig(this.config.travelers);
    this.validations = new WizardValidationState();
  }
}
